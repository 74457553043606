

@import url('https://fonts.googleapis.com/css?family=Lato');

:root {
  --white: #fff;
  --darkgary: #171c1f;
  --peach: #ee6723;
}

body {
  font-family: 'Lato', sans-serif;background: #fff;
  -webkit-font-smoothing: antialiased;
}
a:focus { outline: none }
a:hover { text-decoration: none }
img {
  max-width: 100%;
  height: auto;
}
p {margin-bottom:20px;}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-weight: 700;
  color: var(--darkgary);
}

ul, ol {
  padding-left:20px;
  margin:0 0 30px 0;
}
ul li, ol li {
  font-size:20px;
  padding-left:10px;
  color:#7c8287;
}

h1 {
  margin:0;
  padding:0;
  font-weight: 700;
  font-size: 60px;
  line-height: 80px;
  font-family: 'Lato', serif;
  margin-bottom:20px;
  width:100%;
}

h2 {
  font-size: 46px;
  line-height: 49px;
}

.textLeft {text-align:left;}
.textCenter {text-align:center;}
.textRight {text-align:right;}
.textJustify {text-align:justify;}


.container {
  width: 1300px;
}

.alignleft {
    float: left;
    margin-right: 15px;
}
.alignright {
    float: right;
    margin-left: 15px;
}
.aligncenter {
    display: block;
    margin: 0 auto 15px;
}



.hero-area-bg-sec {position: relative;}
.textContainer {
  position: absolute;
  left:0;
  right:0;
  height:auto;
}
.hero-area-section {
  position: relative;
  margin-top:107px;
  z-index:1;
}
.hero-area-text {
  width: 740px;
  margin:80px auto;
}

.hero-area-text h2 {
  text-align: center;
}




.contentBox {
  padding:62px 0 52px 0;
  width:1000px;
  margin:0 auto;
}

.contentBox p {
  color: #7c8287;
  font-weight: 100;
  font-size: 25px;
}


.contentBox.home {
  text-align: center;
}

.get-stred-btn {
  color: #1b6bb7;
  font-size: 24px;
  line-height: 24px;
  border: 3px solid #1b6bb7;
  padding: 11px 23px;
  width:230px;
  display: inline-block;
  border-radius: 44px;
  position: relative;
  padding-right: 75px;
  margin: 47px auto 0;
  cursor:pointer;
}
.get-stred-btn i {
  position: absolute;
  right: 15px;
  top:7px;
  font-size: 32px;
}
.contentBox {
  margin-top: 25px;
}







.bxdd.expd {
	padding-left: 45px;
	padding-right: 45px;
}


.home h1 {color: var(--dargary);}

.get-stred-btn {
	margin-bottom: 30px;
}


