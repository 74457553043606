
.careers {
    color: #171c1f;
}
.careers .submissions {
    font-weight: 700;
    margin-bottom: 20px;
}

.careersCode code {
    font-size: 16px;
    padding: 1em;
    border-radius: 8px;
    white-space: pre-wrap;
    font-family: Arial, sans-serif;
  }
  .careersCode .keyword {
    color: blue;
  }
  .careersCode .string {
    color: green;
  }
  .careersCode .comment {
    color: grey;
  }
  .careersCode pre {
    counter-reset: line;
  }
  .careersCode pre code {
    counter-increment: line;
    display: block;
    padding-left: 2em;
  }


.careers .submissions strong {
    color: #2557a7;
}

.careers .careerBox {
    border: 1px solid #d4d2d0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
    cursor: pointer;
}

.careers .careerBox .jobTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
}

.careers .careerBox .location {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}

.careers .careerBox .short {
    font-size: 14px;
    color:rgb(63, 63, 63);
    font-weight: 400;
}

.careers .careerDescriptionBox {
    border: 1px solid #d4d2d0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
}

.careers .careerDescriptionBox .jobTitle {
    font-size: 22px;
    font-weight: 700;
}
.careers .careerDescriptionBox .location {
    font-size: 16px;
    font-weight: 500;
}
.careers .careerDescriptionBox .jobDescription {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 12px;
}

.careers .careerDescriptionBox .specs h2 {
    font-size: 22px;
    margin-bottom: 0;
}

.careers .careerDescriptionBox .specs ul li {
    font-size: 16px;
    color: #1b2124;
}

.careers .careerDescriptionBox .consclusion {
    font-size: 16px;
    font-weight: 500;
}
