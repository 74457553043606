/* Large Layout: 1300px. */
@media only screen and (min-width: 1131px) and (max-width: 1300px) { 
	.container {
		width: 100%;
	}
} 

/* Medium Layout: 1280px. */
@media only screen and (min-width: 992px) and (max-width: 1130px) { 
	.container {
		width: 100%;
	}
	.header-container {
		width:100%;	
		margin: 0 auto;
		padding:0 20px;
	}

	.pr-sec-img {
		height:540px;
	}


	.contentBox {
		padding:62px 40px 52px 40px;
		width:100%;
		font-size: 36px;
	}

	.sngle-tm-blk {
		height: 200px;
	}
	.tm-left-text {
		width: 100%;
		text-align: center;
	}
	.tm-right-img {
	  position:absolute;
	  bottom:14px;
	  top: unset;
	  right: unset;
	  width:calc(100% - 40px);
	}

	.tm-right-img img {
	  	max-width: 250px;
	  	display:block;
    	margin:auto;
	}

	.tm-right-img.kiosk {
	  	bottom: 5px;
	}

	.tm-right-img.kiosk img {
	  	max-width: 230px;
	}

	.tm-left-text h4 {
		text-align: center;
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 6px;
		line-height: 27px;
		margin-top: -10px;
	}
	.tm-left-text ul {
		list-style-type: none;
		font-size: 20px;
		font-weight: 300;
		padding:0;
		margin:0;
		font-weight: 200;
		width: 100%;
    	margin: auto;
	
	}
	.tm-left-text ul li {
		display: inline;
      text-align: center;
      padding: 0 3px;
      line-height: 15px;
	}



	.footer-bg-color {
	  width: 100%;
	  padding:53px 20px 0;
	  margin:0 auto;
	}

	
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
	
   .container {
		width: 100%;
	}
	.header-container {
		width:100%;	
		margin: 0 auto;
		padding:0 20px;
	}
	.header-logo {
		top: 8px;
		left:0;
	}

	.pr-sec-img {
		height:440px;
	}

	

	.contentBox {
	  padding:42px 80px 32px 80px;
	  width:100%;
	  font-size: 34px;
	}


	.sngle-tm-blk {
		height: 230px;
	}
	.tm-left-text {
		width: 100%;
		text-align: center;
	}
	.tm-right-img {
	  position:absolute;
	  bottom:14px;
	  top: unset;
	  right: unset;
	  width:calc(100% - 40px);
	}
	.tm-right-img img {
	  	max-width: 100%;
	  	display:block;
    	margin:auto;
	}

	.tm-right-img.kiosk {
	  	bottom: 5px;
	}

	.tm-right-img.kiosk img {
	  	max-width: 230px;
	}

	.tm-left-text h4 {
		text-align: center;
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 6px;
		line-height: 27px;
		margin-top: -10px;
		margin-left: -15px;
    	margin-right: -15px;
	}
	.tm-left-text ul {
		list-style-type: none;
		font-size: 20px;
		font-weight: 300;
		padding:0;
		margin:0;
		font-weight: 200;
		width: 80%;
    	margin: auto;
	
	}
	.tm-left-text ul li {
		display: inline-block;
      text-align: center;
      padding: 0 6px;
      line-height: 15px;
	}



	.footer-bg-color {
	  width: 100%;
	  padding:53px 20px 0;
	  margin:0 auto;
	}

}

/* Mobile Layout: 320px. */
@media only screen and (min-width: 481px) and (max-width: 767px) { 
	.container {
		width: 100%;
	}
	.header-container {
		width:100%;	
		margin: 0 auto;
		padding:0 20px;
	}
	.primaryMenu {display: none;}
	.mobileMenuOpen {display: block;}

	.hero-area-text {
	  width: 100%;
	  padding:0 20px;
	  margin:80px auto;
	}
	.hero-area-text h2 {
		width:100%;
	  font-size: 36px;
	  line-height: 42px;
	}
	.pr-sec-img {
		height:360px;
	}


	.contentBox {
	  padding:32px 50px 22px 50px;
	  width:100%;
	  font-size: 28px;
	}

	.products .npdng {border-right:none;border-bottom: 2px solid #080a0b}
	.products .npdng:last-child {border: none}
	.sngle-tm-blk {
	  margin-bottom:10px;
	}
	.tm-left-text {
		width: 100%;
		float: left;
		position: relative;
		z-index: 2;
	}
	.tm-right-img img {
		max-width: 250px;
		margin-top: 33px;
	}

	.tm-right-img.kiosk img {
	  	margin-top:12px;
	}

	.footer-bg-color {
	  width: 100%;
	  padding:53px 20px 0;
	  margin:0 auto;
	}

}

/* for footer. */
@media only screen and (min-width: 481px) and (max-width: 734px) { 
	.col-ave-company {width:50%;float:left;min-height:170px;}
	.col-ave-products {width:50%;float:left;min-height:170px;}
	.col-ave-about {width:50%;float:left;min-height:170px;}
	.col-ave-social {width:50%;float:left;min-height:170px;}
}

/* Wide Mobile Layout: 480px. */
@media only screen and (max-width: 480px) { 

	h1 {
		font-size:68px;
		line-height: 65px
	}
	.contentBox p {
	  font-size: 26px;
	}

	.container {
		width: 100%;
	}


	.header-container {
		width:100%;	
		margin: 0 auto;
		padding:0 20px;
	}
	.header-logo {
		top: 50px;
		left:0;
	}
	.primaryMenu {display: none;}
	.mobileMenuOpen {display: block;}

	.secondaryMenu ul {
		width: calc(100% + 30px);
    	margin: auto;
    	text-align: center;
    	margin-left:-15px;
    	margin-right:-15px;

	}
	.secondaryMenu nav {
		text-align: center;
	}

	.hero-area-text {
	  width: 100%;
	  padding:0 20px;
	  margin:60px auto;
	}
	.hero-area-text h2 {
		width:100%;
	  font-size: 30px;
	  line-height: 38px;
	}
	.pr-sec-img {
		height:320px;
	}

	

	.contentBox {
	  padding:22px 20px 12px 20px;
	  width:100%;
	  font-size: 24px;
	}


	.products .npdng {border-right:none;border-bottom: 2px solid #080a0b}
	.products .npdng:last-child {border: none}
	.sngle-tm-blk {
	  margin-bottom:10px;
	}
	.tm-left-text {
	  width: 100%;
	  float: left;
	  position: relative;
	  z-index: 2;
	}
	.tm-right-img img {
		max-width: 200px;
		margin-top: 53px;
	}


	.footer-bg-color {
	  width: 100%;
	  padding:53px 20px 0;
	  margin:0 auto;
	}
	.footer-bottom {text-align: center;}
	.footer-content p {width:100%;padding:15px 30px 0;}
	.col-ave-company {width:100%;float:left;margin-bottom:20px;text-align:center;}
	.col-ave-products {width:100%;float:left;margin-bottom:20px;text-align:center;}
	.col-ave-about {width:100%;float:left;margin-bottom:20px;text-align:center;}
	.col-ave-social {width:100%;float:left;margin-bottom:20px;text-align:center;}

}