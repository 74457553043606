
.products .container {padding-bottom: 30px;}
.products .npdng {border-right: 2px solid #080a0b}
.products .npdng:last-child {border-right: none}

.col-md-4.npdng {
  padding: 0px 1px;
}

.bxdd-sec.antrr {
  margin-top: -0px;
}
a.sngle-tm-blk {
  display: block;
  background: #171c1f;
  color: #fff;
  overflow: hidden;
  padding: 22px 18px;
  border-radius: 0;
  transition: all ease-in-out 600ms;
  border-radius: 0;
  cursor: pointer;
  z-index:4;
}

.darkShadow {
  box-shadow: 0 10px 80px 0 #000;
  overflow: visible;
}

.pr-sec-img img {
	width: 100%;
}
.pr-sec-img {
  display: table-cell;
  vertical-align: bottom;
  height:540px;
}

.tm-left-text {
  width: 150px;
  float: left;
  position: relative;
  z-index: 2;
}
.tm-right-img {
  position:absolute;
  top:0;
  right: 19px;
}
.tm-right-img img {
  max-width: 250px;
  margin-top: 53px;
}

.tm-right-img.kiosk img {
  margin-top: 29px;
}

.tm-left-text h4 {
  font-size: 24px;
  font-weight: 400;
  color:#fff;
  margin-bottom: 6px;
  line-height: 27px;
}
.tm-left-text ul {
  list-style-type: none;
  font-size: 20px;
  font-weight: 300;
  padding:0;
  margin:0;
  font-weight: 200;
}
.tm-left-text ul li {
  font-size: 18px;
  color: #fff;
  padding:0;
}

section.cover .container  {
  -webkit-box-shadow: 0px 9px 9px -6px #8c8c8c;
  box-shadow:0px 9px 9px -6px #8c8c8c;
}


.item-thmb-area {
  background: #171c1f;
  padding: 80px 0 20px;
  margin-top: 0;
}

.item-thmb-area p {
  font-weight: 700;
  color:#89979c;
  text-align:center;
  font-size:14px;
  letter-spacing: 1px
}

.item-thmb-area h1 {color: var(--white);}






