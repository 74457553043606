header {
	height:auto;
	width:100%;
	position:fixed;
	top:0;
	left:0;
	right:0;
	z-index:99;
	background:#fff;
	-webkit-box-shadow: 0 5px 8px -4px #00000042;
	-moz-box-shadow: 0 5px 8px -4px #00000042;
	box-shadow: 0 5px 8px -4px #00000042;
}

.header-container {
	width:1040px;	
	height:107px;
	margin: 0 auto;
	position: relative;
}

header .container {
	
}




.header-logo {
	position:absolute;
	max-width: 125px;
	top: 34px;
	left:0;
}

.navigation {
	width:100%;
}



.primaryMenu ul {
	height:61px;
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: right;
}
.primaryMenu ul li {
	padding: 0;
	position: relative;
	display: inline-block;
}
.primaryMenu ul li a {
	padding: 17px 15px;
	display: inline-block;
	color: #7c8287;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none!important;
}
.primaryMenu ul li a.active {
  color: #4e84b7;
  cursor:pointer;
  /*color: #1b6bb7;*/
}
.primaryMenu ul li a:hover {
	color: #4e84b7;
}
.primaryMenu ul li a.active:after {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  background: #1a6bb7;
  content: "";
  border-radius: 3px;
}

.primaryMenu ul li:last-child a {
	padding-right:0;
}






.secondaryMenu ul {
	height:46px;
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: right;
}
.secondaryMenu ul li {
	display: inline-block;
}
.secondaryMenu ul li a {
	display: inline-block;
	padding: 10px 10px;
	color: #7c8287;
	font-weight: 300;
	font-size: 14px;
}
.secondaryMenu ul li a:hover {
	cursor:pointer;
	text-decoration: underline;
}
.secondaryMenu ul li:last-child a {
	background: url(../images/layout/user.png) 10px 12px no-repeat;
	padding-left:31px;
	padding-right:0;
}














/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
}

/* when under viewport size, header is added to body */
header .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: transparent;
	padding: 4px 0;
	z-index: 999999;
}

header a.meanmenu-reveal {
	width: 22px;
	height: 22px;
	padding: 13px 13px 11px 13px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #000;
	text-decoration: none;
	/* font-size: 16px; */
	line-height: 22px;
	font-size: 1px;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
	margin-top: -52px;
}

header a.meanmenu-reveal span {
	display: block;
	background: #000;
	height: 3px;
	margin-top: 3px;
}

header .mean-nav {
	float: left;
	width: 100%;
	background: #0c1923;
	margin-top: 10px;
}

header .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}

header .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}

header .mean-nav ul li a {
	display: block;
	float: left;
	width: 90%;
	padding: 1em 5%;
	margin: 0;
	text-align: left;
	color: #fff;
	border-top: 1px solid #383838;
	border-top: 1px solid rgba(255,255,255,0.5);
	text-decoration: none;
	text-transform: uppercase;
}

header .mean-nav ul li li a {
	width: 80%;
	padding: 1em 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(255,255,255,0.25);
	opacity: 0.75;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
}

header .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}

header .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
}

header .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}

header .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}

header .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.1);
}

header .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	width: 26px;
	height: 32px;
	padding: 12px !important;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: rgba(255,255,255,0.1);
	border: none !important;
	border-left: 1px solid rgba(255,255,255,0.4) !important;
	border-bottom: 1px solid rgba(255,255,255,0.2) !important;
}

header .mean-nav ul li a.mean-expand:hover {
	background: rgba(0,0,0,0.9);
}

header .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}


.primaryMenu ul lid {
	display: inline-block;
}



.mobileMenuOpen {
	width:25px;
	height:27px;
	display:none;
	position: absolute;
	right:0;
	top:70px;
	cursor:pointer;

}

.mobileMenuOpen:hover .dash {
	background: #adadad
}

.mobileMenuOpen .dash {
	width:25px;
	height:1px;
	background:#555555;
	transition: all ease-in-out 200ms
}
.mobileMenuOpen .dash:nth-child(1) {
	position: absolute;
	top:0px;
	transition: all ease-in-out 200ms
}
.mobileMenuOpen .dash:nth-child(2) {
	position: absolute;
	top:7px;
	transition: all ease-in-out 200ms
}
.mobileMenuOpen .dash:nth-child(3) {
	position: absolute;
	top:14px;
	transition: all ease-in-out 200ms
}

.menuOpen .mobileMenuOpen {
	right:20px;
	z-index: 10;
}
.menuOpen .mobileMenuOpen .dash:nth-child(1) {
	transform: rotate(45deg);
	top:7px;
}
.menuOpen .mobileMenuOpen .dash:nth-child(2) {
	top:7px;
	display:none;
}
.menuOpen .mobileMenuOpen .dash:nth-child(3) {
	top:7px;
	transform: rotate(-45deg);
}

#navigationContainer.menuOpen {
	position: fixed;
	background:#ffffff;
	top:0;
	bottom:0;
	left:0;
	right:0;
}

#navigationContainer .header-logo {	display: none; }
#navigationContainer.menuOpen .header-logo {	
	display: block;
	margin: -20px auto 0;
	float: none;
	position: relative;

}
.menuOpen .primaryMenu {display: block;}
.menuOpen .primaryMenu ul {
	height:auto;
	text-align: center;
	margin-top:90px;
}
.menuOpen .primaryMenu ul li {
	display: inline-block;
	width:100%;
	line-height: 15px;

}
.menuOpen .primaryMenu ul li a {
	font-size: 25px;
	color:#232323;
	font-weight: 200
}
.menuOpen .primaryMenu ul li a:hover {
	color:#7c8287;
}
.menuOpen .primaryMenu ul li a:after {
	display: none;
}
