

section.cover {
   position: relative;
   margin-top:107px;
   z-index:1;
   min-height: 460px;
}

section.cover .container {
   padding:0;
   position: relative;
}

section.cover img {
   position:relative;
   z-index: 2
}

section.cover .coverTitle {
   position:absolute;
   top:0;
   bottom:0;
   left:0;
   max-width: 400px;
   height:auto;
   font-size: 46px;
   line-height: 50px;
   color:#fff;
   font-weight:300;
   text-align:left;
   margin:0 auto;
   padding: 50px 40px 20px;
   background: rgba(27, 107, 183, .8);
   z-index: 3;
}

section.cover a.getStartedBtn {
   max-width:217px;
   height:50px;
   border-radius: 25px;
   text-align:center;
   line-height: 50px;
   color:#fff;
   background: #0588f8;
   font-size: 20px;
   font-weight: 400;
   margin:35px auto 0;
   cursor: pointer;
   display: block;
   transition: background 800ms, color 2500ms, box-shadow 1200ms
}

section.cover a.getStartedBtn:hover {
   color:#0588f8;
   background: #fff;
   box-shadow: 0px 0px 0px 3px rgba(255, 150, 0, 1);
}

.contentBox.pages {
   padding: 20px 20px 30px 20px; 
   max-width: 1100px;
}
.pages h1 {
   font-size: 60px;
   line-height: 60px
}
.pages p {
   font-size: 24px;
   text-align: justify;
}
.pages .last {
   margin-bottom:50px;
}

.rectSpace {
   position: relative;
   overflow: hidden;
   padding: 0 20px;
}
.rectSpace.dark {
   background:#171c1f;
}

.rectSpace .contentBox {
   padding: 20px; 
}

.rectSpace h2 {
   font-size: 24px;
   line-height: 28px;
   font-weight: 700;
   margin:0;
   padding:0;
   margin-top:15px;
   margin-bottom:20px;
}
.rectSpace p {
   font-size: 24px;
   color:#fff;
}
.rectSpace.dark h2 {color:#fff;}
.rectSpace ul {
   margin:0;
   padding:0;
   width:100%;
   columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-position: inside;
}

.rectSpace ul.threeCol {
   columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.rectSpace ul li {
   display: block;
   margin-bottom: 20px;
   font-size: 16px;
   list-style-type: none;
}
.rectSpace.dark ul li {color:#89979c;}
.rectSpace .red ul li, .rectSpace .green ul li, .rectSpace .navyBlue ul li, .rectSpace .lightBlue ul li, .rectSpace .darkBlue ul li {color:#fff;}

.rectSpaceBlock { padding:25px 15px; }
.rectSpaceBlock.dark {background:#171c1f;}
.rectSpaceBlock.red {background:#ff5653;}
.rectSpaceBlock.green {background:#38aaa1;}
.rectSpaceBlock.navyBlue {background:#33495e;}
.rectSpaceBlock.lightBlue {background:#00afcc;}
.rectSpaceBlock.darkBlue {background:#1b2f3d;}

.ourClients{ 
   background-color: #E5E5E5;
   
}
.ourClientsContent{ 
   padding: 62px 0 52px 0;
   
   width: 100%;
   margin: 0 auto;
}

.preHeader{
   top: 84px;
   font-family: Lato;
   font-style: normal;
   font-weight: 800;
   font-size: 14px;
   line-height: 17px;
   /* identical to box height */

   letter-spacing: 1px;
   color: #89979C;
}

.clientsLogoContainer{
	display: inline-flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: stretch;
   align-content: stretch;
   margin-bottom:49px;
   max-width:835px;
   
}

.logoContainer{
   width: 170px;
   height: 170px;
   background-color: #FFFFFF;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
   border-radius: 20px;
   margin:15px;
   justify-content: center;
   align-items: center;
   display: flex;
  align-items: center;
}

.logoContainer > img{
   
   margin:0px;
   margin: auto;
   justify-content: center;
   align-items: center;
}

/* RESPONSIVE CODE */


/* Large Layout: 1300px. */
@media only screen and (min-width: 1131px) and (max-width: 1300px) { 
   section.cover {
      min-height: 400px;
   }
}

/* Medium Layout: 1280px. */
@media only screen and (min-width: 992px) and (max-width: 1130px) { 
   section.cover {
      min-height: 351px;
   }

   section.cover .coverTitle {  
      padding: 40px 40px 20px;
      font-size: 44px;
      line-height: 47px;
      max-width: 420px;
   }

   section.cover a.getStartedBtn {
      margin-top:22px;
   }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
   section.cover {
      min-height: 271px;
   }
   section.cover .coverTitle {  
      padding: 24px 25px 20px;
      font-size:36px;
      line-height: 42px;
      max-width: 360px;
   }

   section.cover a.getStartedBtn {
      margin-top:24px;
   }

   

}

/* Mobile Layout: 320px. */
@media only screen and (min-width: 481px) and (max-width: 767px) { 

   section.cover {
      min-height: 350px;
   }

   section.cover .coverTitle {
      position: relative;
      max-width: 100%;
      font-size: 30px;
      line-height: 34px;
      color:#fff;
      background: #1b6bb7;
      text-align:center;
      padding: 20px 40px 20px;
      z-index: 3;
   }

   section.cover a.getStartedBtn {
      margin-top:22px;
   }

   .pages h1 {
      font-size: 65px;
      line-height: 65px;
   }

}

/* Wide Mobile Layout: 480px. */
@media only screen and (max-width: 480px) { 

   section.cover .coverTitle {
      position: relative;
      max-width: 100%;
      font-size: 22px;
      line-height: 26px;
      color:#fff;
      background: #1b6bb7;
      text-align:center;
      padding: 20px 20px 20px;
      z-index: 3;
   }

   section.cover a.getStartedBtn {
      margin-top:22px;
      max-width:190px;
      height:45px;
      line-height: 45px;
      font-size: 18px;
   }

   .pages h1 {
      font-size: 52px;
      line-height: 56px;
      text-align: center;
   }

   .rectSpace ul {
      columns: 1;
     -webkit-columns: 1;
     -moz-columns: 1;
     list-style-position: inside;
   }

   .rectSpace ul.threeCol {
      columns: 2;
     -webkit-columns: 2;
     -moz-columns: 2;
   }

   section.cover {
      min-height: 300px;
   }
}