

.contact .container {
   background: #fff;
   padding-bottom: 50px;
   min-height: 600px
}

.contact .city {
   margin:58px auto 49px;
   max-width: 460px;
   height: auto;
}

.contact .writeUs {
   max-width: 570px;
   height: auto;
   font-size: 31px;
   color:#585858;
   font-weight:300;
   margin: 0 auto;
   padding:0;
   text-align: center;
}

.contact .writeUs > div {
   position:relative;
   width:51px;
   height:10px;
   display: inline-block;
   margin: 0 13px;
}

.contact .writeUs > div > div {
   width:51px;
   height:50px;
   background: url(../images/contact/coffeeImoticon.png) center no-repeat;
   position: absolute;
   top:-25px;
   left:0;
}

.contact .spacer {
  width:280px;
  height: 3px;
  border-radius: 1.5px;
  margin: 40px auto 34px;
  background:#d9d8d8;
}

.contact .contactDetails {
   color:#5e6369;
   font-size: 18px;
   text-align: center;
   font-weight: 300;
}

.contact .contactDetails a {
   color:#5e6369;
}

.contact .submitButton {
   width:180px;
   height: 45px;
   line-height: 45px;
   text-align: center;
   color: #fff;
   font-size: 20px;
   font-weight: 300;
   border-radius: 23px;
   margin:33px auto 30px;
   background: #0588f8;
   cursor: pointer;
   transition: all ease-in-out 400ms
}

.contact .submitButton:hover {
   background: #1183e4;
   cursor: pointer;
   box-shadow: 0px 3px 8px 0px #33333378;
}  

.contact .formContainer {
   max-width:500px;
   height: auto;
   padding:37px 45px;
   border-radius: 15px;
   background: #fff;
   -webkit-box-shadow: 0px 0px 9px 0px #33333378;
   box-shadow: 0px 0px 9px 0px #33333378;
   margin: 60px auto 0;
   position: relative;
}

.contact .formContainer .closeBtn {
   position: absolute;
   top:12px;
   right:12px;
   background: #f49393;
   border-radius: 28px;
   width:56px;
   height:56px;
   text-align: center;
   line-height: 56px;
   font-size: 30px;
   color:#fff;
   font-weight: 300;
   cursor: pointer;
   transition: all ease-in-out 300ms

}

.contact .formContainer .closeBtn:hover {
   background: #ea7b7b;
}

.contact .formContainer .closeBtn img {
   position: absolute;
   top: 20px;
   left: 20px;
}

.contact .formContainer h3 {
   padding:0;
   margin:0;
   font-size: 24px;
   font-weight: 300;
   color:#585858;
   margin-bottom:20px;
}

.contact .formContainer textarea.form-control {
   border:none;
   outline: none;
   box-shadow: none;
   resize: none;
   margin:0;
   padding:0;
   font-size:20px;
   font-weight:300;
   color:#585858;

}
.contact .formContainer input.form-control {
   border:none;
   outline: none;
   box-shadow: none;
   margin:0;
   padding:0;
   border-radius: 0;
   font-size:20px;
   font-weight:300;
   color:#585858;
   
}
.contact .formContainer .spacer {
   width:100%;
   height: 2px;
   border-radius: 1.5px;
   margin: 4px 0;
   background:#d9d8d8;
   margin-bottom:20px;
}
.contact .formContainer .form-group {
   margin-bottom: 40px;
}
.contact .formContainer .form-group ::placeholder { color: #aaaaaa;font-size:20px; font-weight:300; }
.contact .formContainer .form-group :-ms-input-placeholder { color: #aaaaaa;font-size:20px; font-weight:300; }
.contact .formContainer .form-group ::-ms-input-placeholder { color: #aaaaaa;font-size:20px; font-weight:300; }

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset!important;
}














/* Large Layout: 1300px. */
@media only screen and (min-width: 1131px) and (max-width: 1300px) { 

}

/* Medium Layout: 1280px. */
@media only screen and (min-width: 992px) and (max-width: 1130px) { 

}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
   

}

/* Mobile Layout: 320px. */
@media only screen and (min-width: 481px) and (max-width: 767px) { 


}

/* for footer. */
@media only screen and (min-width: 481px) and (max-width: 734px) { 


}

/* Wide Mobile Layout: 480px. */
@media only screen and (max-width: 480px) { 
   .contact .city {
      padding:0;
      margin-bottom:20px;
      margin-top:20px;
   }
   .contact .writeUs {
      padding:0 20px;
      font-size: 28px
   }

}








