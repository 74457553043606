

.footer-area {overflow:hidden;background: #171c1f;}
.footer-area .container {
  color: #d2d2d2;

}
.footer-bg-color {
  width: 1040px;
  margin:0 auto;
  padding:0;
  padding-top: 40px;
  font-size: 16px;
}
.footer-top-rea {
  text-align: center;
  background: #171c1f;
}
.footer-social-area h3 {
  font-size: 20px;
  color: #89979c;
  font-weight: 400;
  margin-bottom: 20px;
}
.social-link a i {
  width: 40px;
  height: 40px;
  background: #8a959e;
  display: inline-block;
  font-size: 23px;
  line-height: 40px;
  border-radius: 50%;
  color: #fff;
  margin: 0 12px;
}
.footer-social-area {
  padding: 55px 0;
}

.footer-logo {
  margin-bottom: 10px;
}
.footer-list ul {
  padding:0;
}
.footer-list li {
  list-style: none;
  padding-left:0;
}
.footer-list li a {
  color: #d2d2d2;
  font-size: 16px;
  line-height: 24px;
}

.footer-list li a:hover {
    color: #1b6bb7;
    text-decoration: underline;
    transition: .3s;
}
.footer-single-blk-title h3 {
  color: #89979c;
  font-size: 14px;
  font-weight: 400;
}

.footer-single-blk-title {
  height: 25px;
  margin-top: 14px;
}
.footer-bg-color .spacer {
  border-top: 2px solid #0c0e0f;
  margin-top: 55px;
  margin-bottom: 0;
}

.footer-bottom {
  height:100px;
  line-height: 100px;
}
.footer-content p {
  width: 206px;
}
.footer-bottom a {
  color: #d2d2d2;
  padding-left: 22px;
}
.footer-bottom p {
  margin:0;
}

.col-ave-company {width:33.333%;float:left;}
.col-ave-products {width:25%;float:left;}
.col-ave-about {width:16.666%;float:left;}
.col-ave-social {width:25%;float:left;}
